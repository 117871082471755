import { useApolloClient } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  apiDataToFormValues,
  formValuesToApiData,
} from 'src/categories/forms/category-form/mappers';
import { useUpdateCategoryMutation } from 'src/categories/gql/mutations/update-category.mutation';
import {
  GetCategoryQuery,
  useGetCategoryQuery,
} from 'src/categories/gql/queries/get-category.query';
import { GetRootCategoriesQuery } from 'src/categories/gql/queries/get-root-categories.query';
import { Page } from 'src/shared/components/page';
import { paths } from 'src/shared/constants/paths';
import { PageHeader } from '../../../shared/components/page-header';
import { CategoryForm } from '../../forms/category-form';

export const UpdateCategory = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ categoryUuid: string }>();
  const { data, error: queryError } = useGetCategoryQuery({
    slugOrUuid: String(params.categoryUuid),
    relations: ['children', 'children.children', 'attributes', 'attributes.values'],
  });
  const [mutate, { loading: updateLoading }] = useUpdateCategoryMutation({
    onCompleted() {
      navigate(location.state?.redirect || paths.categories.list());
      client.refetchQueries({
        include: [GetCategoryQuery, GetRootCategoriesQuery],
      });
    },
  });

  // @TODO: Handle errors in more user-friendly way
  if (queryError) return <div>Loading...</div>;

  return (
    <Page width={40}>
      <PageHeader canGoBack title={`Update Category: ${data.category.name}`} />
      <CategoryForm
        onSubmit={(values) => {
          mutate({
            variables: {
              data: formValuesToApiData(values),
              uuid: String(params.categoryUuid),
            },
          });
        }}
        pending={updateLoading}
        submitLabel="Update category"
        values={apiDataToFormValues(data)}
      />
    </Page>
  );
};
