import { TypedDocumentNode, gql, useSuspenseQuery } from '@apollo/client';
import { Category } from 'src/shared/types/category.type';

export interface GetCategoryData {
  category: Category;
}

export type GetCategoryQueryRelations =
  | 'parent'
  | 'children'
  | 'children.children'
  | 'attributes'
  | 'attributes.values';

interface GetCategoryVariables {
  slugOrUuid: string;
  relations?: GetCategoryQueryRelations[];
}

export const GetCategoryQuery: TypedDocumentNode<GetCategoryData> = gql`
  query GetCategory($slugOrUuid: String!, $relations: [String!]) {
    category(slugOrUuid: $slugOrUuid, relations: $relations) {
      uuid
      name
      slug
      order
      parentUuid
      published
      attributes {
        uuid
        name
        valueType
      }
      children {
        uuid
        name
        slug
        order
        parentUuid
        published
        children {
          uuid
          name
          slug
          order
          parentUuid
          published
        }
      }
    }
  }
`;

export const useGetCategoryQuery = (variables: GetCategoryVariables) => {
  return useSuspenseQuery<GetCategoryData, GetCategoryVariables>(
    GetCategoryQuery,
    { variables }
  );
};
